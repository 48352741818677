import React from 'react'
import Layout from '../layouts/Layout'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Flex } from 'theme-ui'
import { Icon } from '../components/Icon'
import Section from '../components/Section'
import { graphql, Link, StaticQuery } from 'gatsby'
import BasicHero from '../components/Hero/BasicHero'
import BackgroundImageHero from '../components/Hero/BackgroundImageHero'
import leadership from '../data/leadership.json'
const Leadership = ({ data }) => {
  return (
    <Layout theme="light">
      <BackgroundImageHero
        theme="light"
        images={{
          mobile: '../images/hero/Leadership_Mobile.jpg',
          desktop: '../images/hero/Leadership.jpg',
        }}
        heading="Anchored by a team of experienced leaders"
        subHeading="Meet the Attain Finance leadership team at the forefront of our company’s growth and evolution"
      />

      <section
        sx={{
          display: 'flex',
          justifyContent: 'center',
          justifySelf: 'center',
          alignContent: 'center',
          gap: 5,
          py: 6,
          flexWrap: 'wrap',
          maxWidth: 1200,
        }}
      >
        {leadership.map(({ slug, name, title, profileImage }, index) => (
          <Link
            key={index}
            to={slug}
            sx={{ textDecoration: 'none', color: 'black' }}
          >
            <article
              sx={{
                maxWidth: ['100%', null, null, 216],
                px: [4, 0],
                '&:hover': {
                  '.arrow-icon': {
                    bg: 'brand.matisse',
                    path: {
                      fill: 'white',
                    },
                  },
                },
              }}
            >
              <img
                src={profileImage}
                width={216}
                height={216}
                sx={{
                  width: ['80vw', null, '350px', null, '216px'],
                  height: ['auto', null, '350px', null, '216px'],
                  borderRadius: 4,
                  boxShadow: '0 20px 30px rgba(20,20,36,.1)',
                }}
              />
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  pt: 4,
                }}
              >
                <div
                  sx={{
                    maxWidth: [260, null, null, 180],
                  }}
                >
                  <h3
                    sx={{
                      fontSize: [22, 28, null, 18],
                      mb: 2,
                    }}
                  >
                    {name}
                  </h3>

                  <p
                    sx={{
                      fontSize: [16, 18, null, 14],
                      color: '#3e3f4e',
                      fontWeight: 300,
                      m: 0,
                      lineHeight: 1.4,
                    }}
                  >
                    {title}
                  </p>
                </div>

                <Flex
                  className="arrow-icon"
                  sx={{
                    border: '1px solid ',
                    borderColor: 'brand.matisse',
                    height: 30,
                    width: 33,
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 4,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 8.27 8.27"
                    width={10}
                  >
                    <path
                      fill="#4492a7"
                      sx={{
                        fill: 'brand.matisse',
                      }}
                      d="M8.27 0v6.13h-1.3V2.17l-6.1 6.1L0 7.4l6.1-6.1H2.14V0h6.13Z"
                    />
                  </svg>{' '}
                </Flex>
              </Flex>
            </article>
          </Link>
        ))}
      </section>
    </Layout>
  )
}

export default Leadership

export const query = graphql`
  query GetLeaders {
    allLeadershipJson {
      edges {
        node {
          bio
          slug
          name
          title
          profileImage
        }
      }
    }
  }
`
